import React from 'react'
import {
    Grid, 
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Box
} from '@mui/material'
import { SideNavigation } from './components/admin/SideNavigation'
import { WorshipMetaData } from './components/Worship Mode/WorshipMetaData'
import { CurrentSizeContext } from './App'

const pagesArray = [
    {
        id: 'worship-mode',
        element: <WorshipMetaData />
    },
    
  ]

export const DesktopMobileVersion = () => {
    const CurrentSize = React.useContext(CurrentSizeContext)
    const [page, setPage] = React.useState<string>('worship-mode')
    const DesktopVersion = () => (
        <Grid container direction={'row'}>
        <Grid md={2} xl={1.7} item>
          <SideNavigation />
        </Grid>
        <Grid xs={10} md={10} xl={10.3} item>
          {pagesArray.find((x) => x.id == page)?.element}
        </Grid>
      </Grid>
    )

    const MobileVersion = () => (
        <Grid container direction={'column'}>
        <Grid xs={10} item>
          {pagesArray.find((x) => x.id == page)?.element}
        </Grid>
        <Grid md={2} xl={1.7} item>
          <SideNavigation />
        </Grid>
      </Grid>
    )
    return (
        <>
        {CurrentSize.tablet ? <MobileVersion /> :  <DesktopVersion />}
        </>
    )
}