import { createTheme } from '@mui/material/styles';
import { BrandColors } from './Constants';
const theme = createTheme({
    palette: {
      primary : {
        main: BrandColors.DARK_PURPLE,
        light: BrandColors.LIGHT_PURPLE,
        contrastText: "#FFFFFF"
      },
      secondary: {
        light: "#FFFFFF",
        main: "#454442"
      },
      warning: {
        main: "#FFA500",
        dark: "#D19D00"
      }
    },
    typography: {
      button: {
        textTransform: "none",
      }
    }
  });

  export default theme
  