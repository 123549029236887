import React from 'react';
import { SideNavigation } from './components/admin/SideNavigation';
import theme from './Theme'
import { WorshipMetaData } from './components/Worship Mode/WorshipMetaData';
import { ThemeProvider, Grid, useMediaQuery } from '@mui/material';
import {CurrentLoginStatus, LoginWithSession} from './Firebase/AuthStatus'
import { useQuery } from 'react-query';
import { CurrentSizeType } from './Types/SizeType'
import { DesktopMobileVersion } from './DesktopMobileVersionSwitcher';
import { CurrentUserDefault, CurrentUser } from './Constants';

const defaultState = {
  nano: false,
  mobile: true,
  tablet: false,
  desktop: false,
  display: false
}


//export const CurrentUserContext = React.createContext<CurrentUser>(CurrentUserDefault)
export const CurrentSizeContext = React.createContext<CurrentSizeType>(defaultState)
function App() {
  let [isLoggedIn, setIsLoggedIn] = React.useState(false)
  let [user, setUser] = React.useState<CurrentUser>(CurrentUserDefault)
  const {data, isLoading} = useQuery({
    queryKey: ["wor-loggedin"],
    queryFn: () => checkPreviousSession()
  })
  const checkPreviousSession = async() => {
    if(!isLoggedIn){
    try {
      let loginStatus = await CurrentLoginStatus()
      if(loginStatus.status == 200 && loginStatus.data !== "") {
        await LoginWithSession(loginStatus.data)
        setIsLoggedIn(true)
      }
    } catch (e: any) {
      window.location.replace('https://identity.fwcpchurch.org?redirect='+ window.location.href)
    }
  }
  }

  let pageDefaultSizes = {
    nano: useMediaQuery(theme.breakpoints.down('xs')),
    mobile: useMediaQuery(theme.breakpoints.down('sm')),
    tablet: useMediaQuery(theme.breakpoints.down('md')),
    desktop: useMediaQuery(theme.breakpoints.down('lg')),
    display: useMediaQuery(theme.breakpoints.down('xl')),
  }
  
  return (
    <ThemeProvider theme={theme}>
      {/*<CurrentUserContext value={user} >*/}
      <CurrentSizeContext.Provider value={pageDefaultSizes}>
      {//isLoggedIn ? 
      <DesktopMobileVersion />
      //:
      //<></>
}
    </CurrentSizeContext.Provider>
    {/*</CurrentUserContext>*/}
    </ThemeProvider>
  );
}

export default App;
