import {Box, Typography} from '@mui/material'
import {SermonType} from '../../Types/SermonType'
export const LiveStreamInformation = ({series, title, reading} : SermonType) => {
    return(
        <Box paddingLeft={'2vw'}>
            <Typography variant="subtitle1">
            Live Streaming Information
            </Typography>
            {`${title} (${reading})`}
        </Box>
    )
}