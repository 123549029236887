import React from 'react'
import {Box, Divider, Grid} from '@mui/material'
import { BrandColors, BrandType } from '../../Constants'
import '../../Fonts.css'
import { LiveStreamInformation } from './LiveStreamInformation'
import { SermonType } from '../../Types/SermonType'
import { CurrentSizeContext } from '../../App'
const TestSermon = {
    title: "Judas Betrays, Peter Denies, Jesus Saves",
    reading: "John 13:21-28",
    series: "SR7HAYK80"
}
export const WorshipMetaData = () => {
    const CurrentSize = React.useContext(CurrentSizeContext)
    const [now, setNow] = React.useState(new Date())
    const [isLive, setIsLive] = React.useState(true)
    const timeNow = () => setInterval(() => {
        setNow(new Date())
    }, 1000)

    React.useEffect(() => {timeNow()}, [])
    const date = new Date()
    const dayName = ["Lord's Day", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return (
    <>
    <Box 
    bgcolor={BrandColors.GRAY.VERY_LIGHT} 
    height={CurrentSize.tablet ? "85vh" : '100vh'}
    sx={{
        overflow: CurrentSize.tablet ? "hidden" : "",
        overflowY: CurrentSize.tablet ? "scroll": "",
    }}>
        <Grid container direction={'column'}>
            <Grid item xs={1}>
        
        <Grid 
        container 
        paddingY={'2vh'}>
        <Grid item  md={2}>
            <Box sx={{bgcolor: BrandColors.DARK_PURPLE,
                color: 'white',
                fontWeight: BrandType._WEIGHT.SEMIBOLD,
                textAlign: 'center',
                display: 'inline-flex',
                paddingX: '12px',
                marginLeft: '10px'
                }}>
                {dayName[date.getDay()].toUpperCase()}
            </Box>
            </Grid>
            <Grid item md={2}>
                {date.toLocaleDateString("en-us")}
            </Grid>
            <Grid item md={7}>
                {now.toLocaleTimeString("en-gb")}
            </Grid>
            <Grid item md={1}>
                <Box
                sx={{
                    bgcolor: "red",
                    color: 'white',
                    width: '60px',
                    textAlign: 'center',
                    fontWeight: BrandType._WEIGHT.SEMIBOLD,
                    float: 'right',
                    marginRight: '10px',
                    display: isLive ? "inline" : "none"
                }}>
                    LIVE
                </Box>
            </Grid>
        </Grid>
        </Grid>
        <Grid item>
    <Box 
    sx={{
        borderTopLeftRadius: 10, 
        borderBottomLeftRadius: 10, 
        bgcolor: BrandColors.WHITE, 
        overflow: "hidden",
        overflowY: "scroll",
        height: CurrentSize.tablet ? "0" : '88vh',
        }}>
    <Box 
        sx={{
            fontSize: '30px',
            fontWeight: BrandType._WEIGHT.SEMIBOLD,
            fontFamily: BrandType.WORKSANS,
            marginLeft: '30px',
            paddingTop: '20px'
        }}
        >
            WORSHIP {date.toLocaleDateString("en-us")}
        </Box>
    <LiveStreamInformation series={TestSermon.series} reading={TestSermon.reading} title={TestSermon.title}/>
    </Box>
    </Grid>
    </Grid>
    </Box>
    
    </>
    )
}