import React from "react";

export const BrandColors = {
    "DARK_PURPLE": "#4b0082",
    'LIGHT_PURPLE': "#8b56b2",
    VERY_LIGHT_PURPLE: '#c5a0c5',
    'ENGRAFO_MAIN': '#6FA9C6',
    'ENGRAFO_DARK': '#2C5D71',
    'GRAY': {
        "LIGHT": '#808080',
        'REGULAR': '#454442',
        VERY_LIGHT: "#f2f2f2"
    },
    'SUCCESS': '#2d870f',
    'FAILURE': '#940f06',
    'WHITE': "#FFFFFF"
}
export const ErrorFontSizeHeader = 'calc(10px + 1.5em)'
export const ErrorFontSizeText='calc(5px + 0.8em)'
export const BrandType = {
    MONTSERRAT: "'Montserrat', sans-serif",
    WORKSANS: "'Work Sans', sans-serif",
    _WEIGHT: {
        THIN: 100,
        LIGHT: 200,
        REGULAR: 400,
        SEMIBOLD: 600,
        BOLD: 700
    }
}

export const style = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    textAlign: 'left',
    fontWeight: BrandType._WEIGHT.SEMIBOLD
}

export const rowStyle = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    textAlign: 'center',
    fontWeight: BrandType._WEIGHT.REGULAR,
    justifyContent: 'space-between',
    display: 'inline-flex'
}

export const gStyle = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    border: 1,
    textAlign: 'center',
    fontWeight: BrandType._WEIGHT.SEMIBOLD
}

export type CurrentUser = {
    name: string | null
    email: string | null
    profile_picture: string | null
  }

  export const CurrentUserDefault = {
    name: "",
    email: "",
    profile_picture: ""
  }