import { auth } from "./FirebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import Cookie from "js-cookie";
import axios from "axios";
export const CurrentLoginStatus = async () => {
    const csrfCookie = Cookie.get("CSRFToken")
    const sessionCookie = Cookie.get("__session")

    const config = {
    headers: { 'Authorization': `Bearer ${csrfCookie}` },

    }
    let a = await axios.post('https://identity.fwcpchurch.org/_auth/checkAuthStatus',{
        session: sessionCookie
    },config)
    return a
    
}

export const LoginWithSession = async (token: string) => {
    await signInWithCustomToken(auth, token)
}