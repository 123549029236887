import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCHi13CG-2Xdw9dweESIu37rfoacTEuD9U",

  authDomain: "fwcpc-engrafo.firebaseapp.com",

  projectId: "fwcpc-engrafo",

  storageBucket: "fwcpc-engrafo.appspot.com",

  messagingSenderId: "689203219532",

  appId: "1:689203219532:web:cb5cf6b46296d8b739b8e9",

  measurementId: "G-WPMVY4645Z"

};


// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
const analytics = getAnalytics(app);