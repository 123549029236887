import React from "react";
import { 
    Box,
    Grid, 
    ListItemIcon, 
    Divider, 
    createTheme, 
    ThemeProvider, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemButton, 
    BottomNavigation,
    BottomNavigationAction
} from "@mui/material";
import logo from '../../img/WOR-NoBG.png'
import { PresentToAll, AdminPanelSettings, Add, MusicNote} from "@mui/icons-material";
import { BrandColors } from "../../Constants";
import { CurrentSizeContext } from "../../App";
export const SideNavigation = () => {
    const CurrentSize = React.useContext(CurrentSizeContext)
    const theme = createTheme({
        palette: {
          primary: {
            main: BrandColors.WHITE
          },

        },
        components: {
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: BrandColors.VERY_LIGHT_PURPLE,
                        },
                        paddingTop: 2,
                        paddingBottom: 0
                    },
                    
                }
            },


        }
      });
      
    
    const MenuItems = [
        {label: 'Worship Mode', icon: <PresentToAll />},
        {label: 'Praise Admin', icon: <MusicNote />},
        {label: 'Worship Admin', icon: <AdminPanelSettings />},
        {label: 'Update Sermons', icon: <Add />},
        {label: 'Update Bulletin', icon: <Add />},
    ]

    const DesktopNavigation = () => (
        <Box sx={{height: '100vh', 
        bgcolor: BrandColors.GRAY.VERY_LIGHT, 
        borderColor: '#230163',
        borderRight : 0.0,
        textAlign: 'center'}}>
        <Box component={'img'} src={logo} sx={{height: '4.5vw', paddingY: '1vw', maxHeight: '80px'}}/>
        <List>
        {MenuItems.map((item) => (
            <ListItem disableGutters disablePadding>
                <ListItemButton sx={{borderRadius: 30}}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} 
                primaryTypographyProps={{fontSize: 'min(20px ,calc(5px + 1.5vh))'}} />
                </ListItemButton>
            </ListItem>
        ))}
        </List>
        </Box>
    )
    const MobileNavigation = () => (
        <BottomNavigation
        >
            {MenuItems.map((item) => (
                <BottomNavigationAction icon={item.icon} />
            ))}
        </BottomNavigation>
    )

    return (
        <>
        <ThemeProvider theme={theme}>
        {CurrentSize.tablet ? <MobileNavigation /> : <DesktopNavigation />}

        </ThemeProvider>
        </>
    )
}